<template>
    <div><span class="registry_fields_label"></span>
        <label>
            <el-select
                    v-model="local.selectedItem.scenarioNames"
                    @change="$emit('input', local.selectedItem.scenarioNames)"
            >
                <el-option
                        v-for="(item) in local.fields.scenario_names"
                        :key="item.name"
                        :label="item.name"
                        :value="item.name">
                </el-option>
            </el-select>
            Название сценариев
        </label>
        <label>
            <el-select
                    v-model="local.selectedItem.requestTypes"
                    @change="$emit('input', local.selectedItem.requestTypes)"
            >
                <el-option
                        v-for="(item) in local.fields.request_types"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                </el-option>
            </el-select>
            Тип заявки
        </label>
        <label>
            <el-select
                    v-model="local.selectedItem.requestSign"
                    @change="$emit('input', local.selectedItem.requestSign)"
            >
                <el-option
                        v-for="(item) in local.fields.request_sign"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                </el-option>
            </el-select>
            Признак заявки
        </label>
        <label>
            <el-select
                    v-model="local.selectedItem.requestStatuses"
                    @change="$emit('input', local.selectedItem.requestStatuses)"
            >
                <el-option
                        v-for="(item) in local.fields.request_statuses"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                </el-option>
            </el-select>
            Статус заявки
        </label>
        <label>Данные будут скопированы из сценариев программы</label>
    </div>
</template>
<script>
export default {
  props: ['model'],
  name: 'FillScenarioFromSourceCmp',
  data () {
    return {
      local: this.model
    }
  },
  watch: {
    local () {
      this.model = this.local
    },
    model () {
      this.local = this.model
    }
  }
}
</script>

<style scoped>

</style>
